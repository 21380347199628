import { Component, OnInit } from '@angular/core';
import { PortalAuthService } from './portal/services/portal-auth.service';
import { InsightsService } from './shared/services/insights.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private portalAuth: PortalAuthService,
    private insights: InsightsService
  )
  { }

  async ngOnInit() {
    this.portalAuth.service.getLogger().verbose("Msal Portal init");
    this.portalAuth.service.handleRedirectObservable().subscribe();
  }
}
