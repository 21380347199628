import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { APPLICATION_TYPE } from '../../main';
import { ApplicationType } from '../shared/models/application-type';
import { LayoutService } from '../shared/services/layout.service';
import { HomeModule } from './features/home/home.module';
import { NpsLayoutModule } from './features/layout/nps.layout.module';
import { NetworkRequestModule } from './features/network-requests/network-request.module';
import { ProjectsModule } from './features/projects/projects.module';
import { NpsRoutingModule } from './nps-routing.module';
import { NpsAuthService, NpsInterceptor } from './services/nps-auth.service';
import { NpsCommunicationService } from './services/nps-communication.service';
import { ProjectSpokesModule } from './features/project-spokes/project-spokes.module';


@NgModule({
  //declarations: [NpsPermissionDirective],
  imports: [
    ButtonModule,
    MenubarModule,
    ConfirmDialogModule,
    ToastModule,

    NpsLayoutModule,
    HomeModule,

    ProjectsModule,
    ProjectSpokesModule,
    NetworkRequestModule,

    NpsRoutingModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),

    //NpsStore,
    NpsAuthService,
    NpsCommunicationService,

    { provide: APPLICATION_TYPE, useValue: ApplicationType.Nps },

    { provide: HTTP_INTERCEPTORS, useClass: NpsInterceptor, deps: [NpsAuthService, LayoutService], multi: true },
  ],
  //exports: [NpsPermissionDirective],
  //bootstrap: []
})
export class NpsModule {}
