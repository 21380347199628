import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { InjectionToken, enableProdMode } from '@angular/core';
import { AppModule } from './app/app.module';
import { portalEnvironment } from './app/portal/environments/portal-environment';
import { ApplicationType } from './app/shared/models/application-type';

export const APPLICATION_TYPE = new InjectionToken<ApplicationType>('APPLICATION_TYPE');
export const APPLICATION_TENANT = new InjectionToken<ApplicationType>('APPLICATION_TENANT');

export function getBaseUrl() {
  return window.location.origin;
}

if (portalEnvironment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

