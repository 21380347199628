
  <router-outlet></router-outlet>
  <p-toast>
    <!--position="top-center"-->
    <ng-template let-message pTemplate="message">
      <span *ngIf="message.icon" [class]="'p-toast-message-icon pi ' + message.icon"></span>
      <span class="p-toast-message-icon" *ngIf="!message.icon" [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'">
        <!--<ng-container>
          <CheckIcon *ngIf="message.severity === 'success'" [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
          <InfoCircleIcon *ngIf="message.severity === 'info'" [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
          <TimesCircleIcon *ngIf="message.severity === 'error'" [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
          <ExclamationTriangleIcon *ngIf="message.severity === 'warn'" [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
        </ng-container>-->
      </span>
      <div class="p-toast-message-text" [attr.data-pc-section]="'text'">
        <div class="p-toast-summary" [attr.data-pc-section]="'summary'">{{ message.summary }}</div>
        <div class="p-toast-detail" [attr.data-pc-section]="'detail'" innerHtml="{{message.detail}}"></div>
      </div>
    </ng-template>
  </p-toast>
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <!--<router-outlet *ngIf="!isIframe"></router-outlet>-->
