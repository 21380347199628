  <img src="images/confidential.svg" class="m-2" />
  <div class="layout-topbar-logo">
    <a routerLink="" class="flex align-items-center justify-content-center">
      <i class="mr-2 pi pi-microchip"></i>
      <span>Service Portal</span>
    </a>
    @if(layoutService.config.secondLogoText){
    <a routerLink="{{layoutService.config.secondLogoUrl}}" class="flex align-items-center justify-content-center">
      <span class="mx-2">&bull;</span>
      <i class="pi {{layoutService.config.secondLogoIcon}}"></i>
      <span>{{layoutService.config.secondLogoText}}</span>
    </a>
    }
  </div>  


    @if(layoutService.state.sidebarMenuIconVisible){
    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    }

    <!--<button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>-->

    <div #topbarmenu class="layout-topbar-menu">
      <!--[ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}"-->
      <!--<button class="p-link layout-topbar-button">
      <i class="pi pi-calendar"></i>
      <span>Calendar</span>
  </button>-->
      <!--<button class="p-link layout-topbar-button">
    <i class="pi pi-user"></i>
    <span>Profile</span>
  </button>-->
      @if(!(this.portalStore.user$ | async)){
      <div>
        <p-button icon="pi pi-user" styleClass="p-button-sm" (click)="login()" label="Login"></p-button>
      </div>
      }
      @else{
      <div>
        <p-button icon="pi pi-warehouse" styleClass="p-button-sm p-button-text" [label]="(portalStore.tenant$ | async) ?? ''"></p-button>
        <p-button icon="pi pi-user" styleClass="p-button-sm p-button-text" [label]="(this.portalStore.user$ | async)?.username ?? ''"></p-button>
        <p-button styleClass="p-button-sm" (click)="logout()" label="Logout"></p-button>
      </div>
      }
      <!--<button class="p-link layout-topbar-button" [routerLink]="'/'">
      <i class="pi pi-cog"></i>
      <span>Settings</span>
  </button>-->
    </div>

