import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AuthenticationResult, EventError, EventMessage, EventType, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import { BehaviorSubject, Observable, filter, switchMap, take, takeUntil } from 'rxjs';
import { getTenantById } from '../../shared/models/tenant';
import { PortalCommunicationService } from './portal-communication.service';
import { PortalConfigService, PortalMsalType } from "../services/portal-configuration.service";
import { PortalStore } from '../services/portal.store';
import { ApplicationType } from '../../shared/models/application-type';
import { LayoutService } from '../../shared/services/layout.service';

@Injectable({providedIn: 'root'})
export class PortalAuthService {

  public service!: MsalService;
  public inited$ = new BehaviorSubject<boolean>(false);
  public configuration!: PortalConfigService;

  private broadcast!: MsalBroadcastService;

  constructor(
    public store: PortalStore,
    public communication: PortalCommunicationService,
    private router: Router,
    private layout: LayoutService,
    location: Location
  ) {
    this.layout.startLoading();
    //decide which type of login to use
    //if not standard login page, use value from storage or default
    if (window.location.pathname.indexOf('member-login') > -1) {
      this.init(PortalMsalType.Member, location);
    } else {
      if (store.current.msalType) {
        this.init(store.current.msalType, location);
      } else {
        this.init(store.current.msalType ?? PortalMsalType.O365, location);
      }
    }

    this.communication.userLogin$.subscribe(() => {
      this.login();
    })

    this.communication.userLogout$.subscribe(() => {
      this.communication.logoutAllApps().then(() => {
        this.logout();
      });
    })

    this.communication.userLogoutCompleted$.subscribe(() => {
      //reload after logout
      window.location.reload();
    })

    this.communication.userLoginCompleted$
      .pipe(filter((f) => f != null), take(1))
      .subscribe(() => {
        this.layout.stopLoading();
      });

    //set if already logged in
    this.setActiveAccount();

    //watch for clear local storage event 
    window.addEventListener('storage', (d: StorageEvent) => this.storageListener(d));
  }

  getAuthToken(): Observable<AuthenticationResult> {
    return this.service.acquireTokenSilent({
      scopes: this.configuration.getMsalScopes()
    });
  }

  storageListener(d: StorageEvent) {
    //console.log('StoreListener', d);
    if (!d.key && !d.oldValue && !d.newValue) {
      //clean store
      //console.log('StoreListener: cleaning');
      this.communication.userLogout$.next();
    }
  }


  private init(msalType: PortalMsalType, location: Location) {
    this.store.setMsalType(msalType);
    switch (msalType) {
      case PortalMsalType.O365:
        this.initO365(location);
        break;
      case PortalMsalType.Member:
        this.initMember(location);
        break;
    }
  }

  private initO365(location: Location) {
    this.configuration = new PortalConfigService(PortalMsalType.O365);
    this.service = new MsalService(new PublicClientApplication(this.configuration.getMsalConfig()), location);
    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    console.log('PortalAuthService.init Type: ', PortalMsalType.O365);
  }

  private initMember(location: Location) {
    this.configuration = new PortalConfigService(PortalMsalType.Member);
    this.service = new MsalService(new PublicClientApplication(this.configuration.getMsalConfig()), location);
    this.broadcast = new MsalBroadcastService(this.service.instance, this.service);

    this.initBroadcast();

    console.log('PortalAuthService.init Type:', PortalMsalType.Member);
  }

  private login(): Observable<AuthenticationResult> {
    return this.service.loginPopup({
      scopes: this.configuration.getMsalScopes()
    });
  }

  private logout(): Observable<void> {
    this.store.clean();
    const account = this.store.current.user;
    return this.service.logoutPopup({ account });
  }

  private initBroadcast() {
    const events: EventType[] = [EventType.INITIALIZE_END, EventType.LOGIN_FAILURE, EventType.LOGOUT_END];
    this.broadcast.msalSubject$
      .pipe(
        filter((msg: EventMessage) => events.includes(msg.eventType)),
      )
      .subscribe(async (msg: EventMessage) => {

        switch (msg.eventType) {
          case EventType.INITIALIZE_END:
            this.inited$.next(true);
            console.log('PortalAuthService.broadcast: PortalAuth inited');
            break;
          case EventType.LOGIN_FAILURE:
            console.error('PortalAuthService.broadcast: Login failed', msg.error);
            this.store.setTenant(undefined);
            this.loginFailedRedirect(msg.error);
            break;
          case EventType.LOGOUT_END:
            console.log('PortalAuthService.broadcast: Logout completed');
            this.communication.userLogoutCompleted$.next();
        }
      });

    this.broadcast.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        //takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setActiveAccount();
      })
  }

  private setActiveAccount() {
    let activeAccount = this.service.instance.getActiveAccount();

    if (!activeAccount && this.service.instance.getAllAccounts().length > 0) {
      activeAccount = this.service.instance.getAllAccounts()[0];
      this.service.instance.setActiveAccount(activeAccount);
    }
    if (activeAccount) {
      this.store.setUser(activeAccount);
      console.log("PortalAuthService: user logged in", activeAccount);
      let tenant = getTenantById(activeAccount?.tenantId ?? '');
      if (tenant && !this.store.current.tenant) {
        this.store.setTenant(tenant);
      }

      this.communication.userLoginCompleted$.next(activeAccount);
    }
  }

  private loginFailedRedirect(error?: EventError) {
    console.log('PortalAuthService: redirect to login failed page', error);
    if (error) {
      this.communication.userLoginCompleted$.next(error);
    }
    this.router.navigate(['/error/login-failed']);
  }
}

export const PortalGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  //TODO: add error handling
  const auth = inject(PortalAuthService);
  return new Promise((resolve, reject) => {
    auth.inited$
      .pipe(filter(f => f), take(1))
      .subscribe((inited) => {
        if (inited) {
          if (!auth.store.current.user) {
            auth.communication.userLogin$.next(ApplicationType.Portal);
            auth.communication.userLoginCompleted$
              .pipe(filter(f => f != null), take(1))
              .subscribe((r) => {
                resolve(true);
              });
          }
          resolve(inited);
        }
      });
  })
};


@Injectable()
export class PortalInterceptor implements HttpInterceptor {

  constructor(private authService: PortalAuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //TODO: add error handling
    const isProtectedResource = req.url.startsWith(this.authService.configuration.apiUrl) || false;
    if (isProtectedResource) {
      return this.authService.getAuthToken().pipe(
        switchMap((result: AuthenticationResult) => {
          const headers = req.headers.set(
            "Authorization",
            `Bearer ${result.accessToken}`
          );

          const requestClone = req.clone({ headers });
          return next.handle(requestClone);
        })
      );
    } else {
      // If not protected, forward the original request
      return next.handle(req);
    }
  }
}
