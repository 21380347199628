import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { portalEnvironment } from '../../portal/environments/portal-environment';

@Injectable()
export class InsightsService {
  private angularPlugin?: AngularPlugin;
  private appInsights?: ApplicationInsights;

  constructor(private router: Router) {
    if (portalEnvironment.appInsightsKey) {
      this.angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: portalEnvironment.appInsightsKey,
          extensions: [this.angularPlugin],
          extensionConfig: {
            [this.angularPlugin.identifier]: {
              router: this.router,
              errorServices: [new ErrorHandler()],
            },
          },
        },
      });

      this.appInsights.loadAppInsights();
    }
  }

  // expose methods that can be used in components and services
  trackEvent(name: string): void {
    this.appInsights?.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights?.trackTrace({ message });
  }
}
