import { Component } from '@angular/core';
import { ApplicationType } from '../../../../shared/models/application-type';
import { LayoutService } from '../../../../shared/services/layout.service';
import { PortalStore } from '../../../services/portal.store';
import { PortalCommunicationService } from '../../../services/portal-communication.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  constructor(
    private communication: PortalCommunicationService,
    public layoutService: LayoutService,
    public portalStore: PortalStore
  ) { }

  login() {
    this.communication.userLogin$.next(ApplicationType.Portal);
  }

  logout() {
    this.communication.userLogout$.next();
  }
}
