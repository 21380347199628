import { Component } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { AppLayoutComponent } from './app.layout.component';

@Component({
    selector: 'portal-layout',
    templateUrl: './portal.layout.component.html'
})
export class PortalLayoutComponent extends AppLayoutComponent {
  constructor(layoutService: LayoutService) {
    super(layoutService);

    layoutService.config.secondLogoText = null;
    layoutService.config.secondLogoIcon = '';

  }
}
