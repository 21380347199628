import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { ApplicationType } from "../../shared/models/application-type";
import { TenantKey } from "../../shared/models/tenant";
import { npsEnvironment } from "../environments/nps-environment";

export class NpsConfigService {

  private _tenant?: TenantKey;

  constructor(tenant?: TenantKey) {
    this._tenant = tenant;
  }

  public setTenant(tenant?: TenantKey) {
    this._tenant = tenant;
  }

  get applicationType(): ApplicationType { return ApplicationType.Nps; }

  get tenant(): TenantKey | undefined { return this._tenant; }
  get apiUrl(): string { return this._tenant ? npsEnvironment.api[this._tenant!]!.url : ''; }
  get msalAuthority(): string { return this._tenant ? npsEnvironment.api[this._tenant!]!.authority : ''; }

  getMsalScopes(): string[] {
    return this._tenant ? npsEnvironment.api[this._tenant!]!.scopes : [];
  }

  getMsalConfig(): Configuration {
    return this._tenant ? {
      auth: {
        clientId: npsEnvironment.api[this._tenant!]!.clientId,
        authority: npsEnvironment.api[this._tenant!]!.authority,
        redirectUri: window.location.origin
        //redirectUri: `/nps-login?redirect=${window.location.href}`,// + '/auth',
        //navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        allowRedirectInIframe: true,
        loggerOptions: {
          loggerCallback: (logLevel: LogLevel, message: string) => console.log(message),
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    } : {} as Configuration;
  }
}
