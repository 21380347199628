import { Component, OnInit } from '@angular/core';
import { NpsCommunicationService } from '../../../services/nps-communication.service';

@Component({
  selector: 'nps-logout',
  templateUrl: './logout.component.html',
})
export class NpsLogoutComponent implements OnInit {

  constructor(
    private communication: NpsCommunicationService,
    //private route: ActivatedRoute,
    //private portalCommunication: PortalCommunicationService
  ) {

    //const redirectTo = this.route.snapshot.queryParams['redirect'] ?? '/';

  }

  async ngOnInit() {
    //this.npsAuth.logout()
    this.communication.userLogout$.next();
  }


}
