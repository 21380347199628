import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';

import { AppLayoutModule } from '../../../portal/features/layout/app.layout.module';
import { NpsSharedModule } from '../../nps-shared.module';
import { NpsLayoutLiteComponent } from './components/nps.layout-lite.component';
import { NpsLayoutComponent } from "./components/nps.layout.component";
import { NpsMenuComponent } from './components/nps.menu.component';
import { NpsSidebarComponent } from "./components/nps.sidebar.component";
import { NpsProgressLayoutComponent } from './components/progress.layout.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    NpsMenuComponent,
    NpsSidebarComponent,
    NpsLayoutComponent,
    NpsLayoutLiteComponent,
    NpsProgressLayoutComponent
  ],
  imports: [
    CommonModule,
    AppLayoutModule,
    ButtonModule,
    MenuModule, 
    SidebarModule,
    RippleModule,
    RouterModule,
    ProgressBarModule,
    NpsSharedModule
  ],
  exports: [NpsLayoutComponent]
})
export class NpsLayoutModule { }
