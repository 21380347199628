import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NpsPermissionDirective } from './directives/nps-permission.directive';


@NgModule({
  declarations: [NpsPermissionDirective],
  imports: [CommonModule],
  exports: [NpsPermissionDirective] // Export so it can be used in other modules
})
export class NpsSharedModule { }
