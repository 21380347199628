import { Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { filter } from 'rxjs';
import { UserDetailsService } from '../features/shared/api/user-details.service';
import { NpsStore } from '../services/nps.store';

@Directive({
  selector: '[npsPermission]'
})
export class NpsPermissionDirective implements OnInit, OnDestroy {
  @Input() npsPermission: string = ''; // endpoint name
  private visible = false;

  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserDetailsService,
    private store: NpsStore
  ) {}

  ngOnInit() {
    //subscribe to user permissions
    this.store.npsUser$
      .pipe(filter(p => p != undefined))
      .subscribe(user => this.updateView(this.userService.hasPermission(this.npsPermission, user?.roles)));
  }

  ngOnDestroy() {
    this.clearView();
  }  

  private updateView(show: boolean) {
    if (show && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    if (!show && this.visible) {
      this.clearView();
    }
    this.visible = show;
  }

  private clearView() {
    this.viewContainer.clear();
  }
}
