<div class="flex mt-6 justify-content-center">

  <p-card styleClass="p-5">
    <!--header="Login in progress"-->
    <h3 class="text-center">Logout</h3>

    <div class="text-center">
      <i class="pi pi-rotate pi-microchip" style="font-size: 3rem"></i>
      <br />
      Please wait
    </div>
  </p-card>

</div>
