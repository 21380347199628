import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';

import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home.component';
import { NpsLoginComponent } from './components/login.component';
import { CardModule } from 'primeng/card';
import { NpsLogoutComponent } from './components/logout.component';
import { NpsSharedModule } from '../../nps-shared.module';

@NgModule({
  declarations: [
    HomeComponent,
    NpsLoginComponent,
    NpsLogoutComponent
  ],
  imports: [
    NpsSharedModule,
    CardModule,
    RouterModule,
    CommonModule,
    ButtonModule,
    RouterModule
  ]
})
export class HomeModule {}

