import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PortalAuthService } from '../../../services/portal-auth.service';

@Component({
  //selector: 'app-home',
  template: '',
})
export class MemberLoginComponent {
  constructor(
    //location: Location,
    router: Router,
    auth: PortalAuthService //init in constructor
  ) {
    //set config for login
    //auth.init(PortalMsalType.Member, location);

    //rediret to home page
    router.navigate(['/']);
  }
}
