import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor(
    router: Router
  ) {
    //if user has no projects then we need to redirect him to the network request page

  //  const me = userStore.current.user;
  //  const networkRequestStart = '/network-requests/new';
  //  if (!me || Object.keys(me.projects).length == 0) {
  //    router.navigateByUrl(networkRequestStart);
  //    //console.log(networkRequestStart);
  //  }
  }
}
