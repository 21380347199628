import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../../../shared/services/layout.service';
import { AppTopBarComponent } from './app.topbar.component';

@Component({
  selector: 'app-layout',
  templateUrl: './app.layout.component.html'
})
export class AppLayoutComponent implements OnInit {

  @Input() containerClass: object = {};

  @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

  constructor(public layoutService: LayoutService) {
  }

  async ngOnInit() {
  }

  //        'layout-static': this.layoutService.config().menuMode === 'static',
  //        'layout-static-inactive': true, //hide sidebar menu
  //        'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,

}
